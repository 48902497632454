import { withCompController } from '@wix/editor-elements-integrations';
import {
  ISlideshowMapperProps,
  ISlideshowControllerProps,
  ISlideshowProps,
  ISlideshowStateRefValues,
} from '../../types';

export default withCompController<
  ISlideshowMapperProps,
  ISlideshowControllerProps,
  ISlideshowProps,
  ISlideshowStateRefValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { updateProps } = controllerUtils;

  return {
    ...mapperProps,
    setCurrentIndex: (option1, option2) => {
      // TODO once TB will be released - remove this
      if (typeof option1 === 'number') {
        updateProps({
          currentIndex: option1,
          previewItemId: option2,
          navigatingForward: option1 > mapperProps.currentIndex,
        });
        return;
      }
      const { currentIndex, previewItemId = '', navigatingForward } = option1;
      updateProps({
        currentIndex,
        previewItemId,
        navigatingForward:
          navigatingForward ?? currentIndex > mapperProps.currentIndex,
      });
    },
    observeChildListChange: stateValues.observeChildListChange,
  };
});
