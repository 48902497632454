import Repeater_SlideshowComponent from '../components/Repeater/viewer/skinComps/Slideshow/Slideshow.skin';
import Repeater_SlideshowController from '../components/Repeater/viewer/Repeater.controller';
import SlideshowComponent from '../components/Slideshow/Slideshow/viewer/Slideshow';
import SlideshowController from '../components/Slideshow/Slideshow/viewer/Slideshow.controller';
import SlideshowButtonComponent from '../components/Slideshow/SlideshowButton/viewer/SlideshowButton';
import SlideshowButtonController from '../components/Slideshow/SlideshowButton/viewer/SlideshowButton.controller';
import SlideshowIndicatorComponent from '../components/SlideshowIndicator/viewer/SlideshowIndicator';
import SlideshowIndicatorController from '../.components/SlideshowIndicator/viewer/SlideshowIndicator.controller';


const Repeater_Slideshow = {
  component: Repeater_SlideshowComponent,
  controller: Repeater_SlideshowController
};

const Slideshow = {
  component: SlideshowComponent,
  controller: SlideshowController
};

const SlideshowButton = {
  component: SlideshowButtonComponent,
  controller: SlideshowButtonController
};

const SlideshowIndicator = {
  component: SlideshowIndicatorComponent,
  controller: SlideshowIndicatorController
};


export const components = {
  ['Repeater_Slideshow']: Repeater_Slideshow,
  ['Slideshow']: Slideshow,
  ['SlideshowButton']: SlideshowButton,
  ['SlideshowIndicator']: SlideshowIndicator
};

