import React, { useEffect, useImperativeHandle } from 'react';
import ResponsiveContainer from '@wix/thunderbolt-elements/components/ResponsiveContainer';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import cn from 'clsx';
import { SlideshowContextProvider } from '../../SlideshowContext';
import { ISlideshowProps, ISlideshowImperativeActions } from '../../types';
import style from './styles/Slideshow.scss';

const Slideshow: React.ForwardRefRenderFunction<
  ISlideshowImperativeActions,
  ISlideshowProps
> = (props, ref) => {
  const {
    id,
    children,
    className,
    containerProps,
    containerRootClassName,
    currentIndex,
    setCurrentIndex,
    observeChildListChange,
    isPlaying,
    onPlay,
    onPause,
    onChange,
    onMouseEnter: onMouseEnterVelo,
    onMouseLeave: onMouseLeaveVelo,
    loop,
    navigatingForward,
    slideDuration,
    pauseOnHover,
    ariaAttributes,
  } = props;

  const [isHovered, setIsHovered] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const itemsWrapperRef = React.useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({
    setCurrentIndex,
  }));

  useEffect(() => {
    isPlaying ? onPlay?.({ type: 'onPlay' }) : onPause?.({ type: 'onPause' });
  }, [isPlaying, onPlay, onPause]);

  useEffect(() => {
    onChange?.({ type: 'onChange' });
  }, [currentIndex, onChange]);

  const onMouseEnter = (e: React.MouseEvent) => {
    if (pauseOnHover) {
      setIsHovered(true);
    }
    onMouseEnterVelo?.(e);
  };

  const onMouseLeave = (e: React.MouseEvent) => {
    setIsHovered(false);
    onMouseLeaveVelo?.(e);
  };

  React.useEffect(() => {
    const elementRef = containerProps.shouldOmitWrapperLayers
      ? containerRef
      : itemsWrapperRef;
    if (observeChildListChange && elementRef?.current) {
      observeChildListChange(id, elementRef.current as HTMLElement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      id={id}
      ref={containerRef}
      {...getDataAttributes(props)}
      className={cn(containerRootClassName, className, style.slideshow)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      {...ariaAttributes}
      role={ariaAttributes?.role || 'group'}
    >
      <SlideshowContextProvider
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        loop={loop}
        navigatingForward={navigatingForward}
        isPlaying={isPlaying}
        isHovered={isHovered}
        isFocused={isFocused}
        slideDuration={slideDuration}
        containerRef={containerRef}
      >
        <ResponsiveContainer ref={itemsWrapperRef} {...containerProps}>
          {children}
        </ResponsiveContainer>
      </SlideshowContextProvider>
    </div>
  );
};

export default React.forwardRef(Slideshow);
